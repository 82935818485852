.app-navbar {
    position: fixed;
    padding: 20px 10px;
    border: solid 2px var(--future-tax-8);
    background-color: var(--future-tax-4) !important;
    width: 100%;
    border-top: 8px solid var(--future-tax-100);
    z-index: 9;
    top: 0
}

.navbar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.glass {
    background-color: rgba(255, 255, 255, 0.4) !important;
    backdrop-filter: blur(18px) !important;
    -webkit-backdrop-filter: blur(18px) !important;
    transition: all 0.2s ease-in !important;
}

.transparent {
    background-color: rgba(0, 0, 0, 0) !important;
}

.language-select>.MuiSelect-select:focus {
    background: none !important;
}