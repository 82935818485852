:root {
    --primary: #011C3B;
    --white: #FCFDFF;
    --bg_light: #F2F4F7;
    --bg_dark: #E8EBF0;
    --bg_disabled: #DFE3E9;
    --bg_success: #F6FDFA;
    --bg_error: #FDF6F5;
    --disabled_content: #B4BAC4;
    --red: #CE311E;
    --green: #58BF7D;
    --yellow: #F29C38;
    --grey: #4f4f4f;
    --grey_dark: #373737;
    --grey_dark1: #282828;
    --black: #101010;
}

.eclear-bg {
    background-image: url(../assets/eclear_bg.jpg) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.2s ease-in !important;
}


.section {
    min-height: 100vh !important;
    min-width: 100vw !important;
}

.center-v-h {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.glass-surface {
    background-color: rgba(255, 255, 255, 0.3);
    padding: 30px;
    border-radius: 20px;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.04);
    width: auto;
    height: auto;
    transition: all 0.2s ease-in !important;
}

.HeadlineH7 {
    font-family: Theinhardt;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.HeadlineH2 {
    font-family: Theinhardt;
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.HeadlineH3 {
    font-family: Theinhardt;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.HeadlineH4 {
    font-family: Theinhardt;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.HeadlineH5 {
    font-family: Theinhardt;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.HeadlineH6 {
    font-family: Theinhardt;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.ParagraphMediumBold {
    font-family: Theinhardt;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
}

.ParagraphMediumDefault {
    font-family: Theinhardt;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
}

.ParagraphSmallBold {
    font-family: Theinhardt;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
}

.HeadlineH8 {
    font-family: Theinhardt;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.ParagraphSmallDefault {
    font-family: Theinhardt;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
}

.NoteBold {
    font-family: Theinhardt;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
}

.NoteDefault {
    font-family: Theinhardt;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
}

.DisclaimerBold {
    font-family: Theinhardt;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.DisclaimerDefault {
    font-family: Theinhardt;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.dropzone {
    background: var(--future-tax-4);
    transition: all 0.15s ease-in;
}

.dropzone.error {
    background: var(--error-8)
}
