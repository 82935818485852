.breadcrumb-link {
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: left;
    color: var(--future-tax-70) !important;
    padding-bottom: 4px;
    border-bottom: 2px solid var(--future-tax-20);
    text-decoration: none;
    transition: all 0.15s ease;
    cursor: pointer;
}

.breadcrumb-link:hover {
    transition: all 0.15s ease;
    border-color: var(--future-tax-70);
}

.MuiBreadcrumbs-separator {
    font-size: 14px;
    font-weight: 500;
    color: var(--future-tax-70);
    margin: 0px 10px !important;
}

.last-breadcrumb {
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: left;
    color: var(--future-tax-80) !important;
    margin-top: 4px !important;
}