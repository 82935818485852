.logo-container {
    background-color: var(--primary) !important;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.logo {
    height: 28px;
    object-fit: contain;
    user-select: none;
}

.backoffice-logo {
    font-family: Theinhardt;
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    line-height: 1;
    text-align: left;
    color: var(--future-tax-140);
    padding-left: 9px;
    cursor: pointer;
    user-select: none;
}