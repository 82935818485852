:root {
    --template: #9747ff;
    --template: #816dd0;
    --overlay-future-tax-80: rgba(0, 24, 61, 0.8);
    --overlay-future-tax-60: rgba(0, 24, 61, 0.6);
    --overlay-future-tax-40: rgba(0, 24, 61, 0.4);
    --overlay-future-tax-20: rgba(0, 24, 61, 0.2);
    --overlay-future-tax-8: rgba(0, 24, 61, 0.08);
    --future-tax-140: #000e25;
    --future-tax-120: #001331;
    --future-tax-110: #001637;
    --future-tax-100: #00183d;
    --future-tax-90: #192f50;
    --future-tax-80: #324664;
    --future-tax-60: #65748b;
    --future-tax-40: #97a1b1;
    --future-tax-20: #cacfd8;
    --future-tax-8: #e8ebef;
    --future-tax-4: #f2f4f7;
    --future-tax-0: #fcfdff;
    --success-20: #ccf3e4;
    --warning-20: #ffeacc;
    --error-20: #f9d0cc;
    --success-100: #00c376;
    --warning-100: #ff9800;
    --error-100: #e01600;
    --success-80: #33cf91;
    --success-40: #99e7c8;
    --success-8: #ebfaf4;
    --success-4: #f5fdfa;
    --warning-80: #ffad33;
    --warning-40: #ffd699;
    --warning-8: #fff7eb;
    --warning-4: #fffbf5;
    --error-4: #fef6f5;
    --error-8: #fdeceb;
    --error-40: #f3a299;
    --error-80: #e74533;
    --success-120: #00a167;
    --success-140: #007f59;
    --warning-108: #eb8e04;
    --warning-120: #cc7e09;
    --warning-140: #996512;
    --warning-104: #f59302;
    --warning-60: #ffc166;
    --success-60: #66dbad;
    --success-104: #00bc73;
    --success-108: #00b570;
    --error-120: #b31609;
    --error-108: #ce1604;
    --error-104: #d71602;
    --innovation-100: #005ae0;
    --error-60: #ed7366;
    --innovation-80: #337be6;
    --innovation-40: #99bdf3;
    --innovation-20: #ccdef9;
    --innovation-8: #ebf2fd;
    --innovation-4: #f5f8fe;
    --innovation-60: #719ce7;
    --innovation-120: #004dbc;
    --innovation-140: #004098;
    --error-140: #861712;
    --innovation-108: #0055d2;
    --innovation-104: #0057d9;
    --vision-100: #ac9175;
    --vision-80-light: #bca791;
    --vision-40-light: #dcd2c8;
    --vision-60-light: #cdbdac;
    --vision-8-light: #f6f4f4;
    --vision-20-light: #ece7e3;
    --vision-4-light: #f9f9f9;
    --vision-108: #9a8774;
    --vision-140: #66615f;
    --vision-104: #a18c76;
    --vision-120: #87796c;
    --template: #816dd0;
    --future-tax-180: #00050c;
    --future-tax-104: #00173b;
    --future-tax-108: #001638;
    --future-tax-160: #000a18;
    --future-tax-190: #000206;
    --success-190: #002934;
    --success-180: #003a3c;
    --success-160: #005c4a;
    --warning-180: #333224;
    --warning-190: #1a2528;
    --warning-160: #664b1b;
    --error-190: #161828;
    --innovation-190: #001f3f;
    --error-180: #2d1824;
    --innovation-180: #002551;
    --error-160: #5a171b;
    --innovation-160: #003275;
    --future-tax-70: #4d5d77;
    --success-70: #4dd59f;
    --error-70: #e95c4d;
    --warning-70: #ffb74d;
    --innovation-70: #4d8bea;
    --white: #ffffff;
}

.admin-filter-button {
    width: 28px;
    height: 28px;
    border-radius: 5px !important;
}

.sticky-column {
    position: sticky;
    right: -5px;
}

.admin-dashboard-data {
    overflow: auto;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #00183d81;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #00183d;
}

::-webkit-scrollbar-thumb:active {
    background: #00183d;
}

@font-face {
    font-family: 'Theinhardt';
    src: url("../../../../assets/fonts/Theinhardt-LightItalic.woff2") format('woff2'),
    url("../../../../assets/fonts/Theinhardt-LightItalic.woff") format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Theinhardt';
    src: url("../../../../assets/fonts/Theinhardt-Light.woff2") format('woff2'),
    url("../../../../assets/fonts/Theinhardt-Light.woff") format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Theinhardt';
    src: url("../../../../assets/fonts/Theinhardt-Italic.woff2") format('woff2'),
    url("../../../../assets/fonts/Theinhardt-Italic.woff") format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Theinhardt';
    src: url("../../../../assets/fonts/Theinhardt-Medium.woff2") format('woff2'),
    url("../../../../assets/fonts/Theinhardt-Medium.woff") format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Theinhardt';
    src: url("../../../../assets/fonts/Theinhardt-Regular.woff2") format('woff2'),
    url("../../../../assets/fonts/Theinhardt-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Theinhardt';
    src: url("../../../../assets/fonts/Theinhardt-MediumItalic.woff2") format('woff2'),
    url("../../../../assets/fonts/Theinhardt-MediumItalic.woff") format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

body {
    font-family: "Theinhardt";
    min-height: 100vh;
    overflow-y: scroll;
}

* {
    font-family: "Theinhardt" !important
}


.sticky-column {
    background-color: var(--future-tax-0) !important;
}

.admin-table-cell {
    color: var(--future-tax-80);
}

.admin-table-cell {
    line-height: 40px !important;
}

.onbarding-input > div > fieldset {
    border-color: rgba(0, 0, 0, 0) !important;
}

.admin-date-picker {
    width: 100% !important;
    background: var(--future-tax-8);
    border-radius: 5px;
}


/* .btn-ad.Mui-disabled.MuiButton-contained {
    background-color: var(--future-tax-40) !important;
    color: var(--future-tax-4) !important
} */
