.ENABLED {
    background-color: var(--future-tax-4);
    color: var(--future-tax-80);
}

.INVITED {
    background-color: var(--error-100);
    color: var(--future-tax-0);
}

.REGISTERED {
    background-color: var(--warning-100);
    color: var(--future-tax-0);
}

.VERIFIED {
    background-color: var(--future-tax-4);
    color: var(--future-tax-80);
}

.undefined {
    background-color: var(--future-tax-0);
    color: var(--future-tax-80);
}