.card {
  border-radius: 8px;
  box-shadow: -1px -1px 1px hsla(0, 0%, 100%, 0.6), 2px 1px 2px rgb(0 0 0/6%),
    inset -1px -1px 2px rgb(0 0 0/8%), inset 1px 1px 2px hsla(0, 0%, 100%, 0.6) !important;
}
.overview-card .header,
.version-list-card .header {
  border-bottom: 2px solid #e8ebef;
  padding: 10px 12px 10px 12px;
}
.version-form {
  margin-bottom: 20px;
}
.overview-card .MuiCardHeader-title,
.version-list-card .MuiCardHeader-title,
.version-form .title,
.version-detail .title {
  font-size: 18px;
  font-weight: 500;
}
.overview-card .icon,
.version-list-card .icon {
  font-size: 22px;
  color: #00183d;
}
.country-card {
  background-color: #f2f4f7 !important;
}
.country-flex {
  display: flex;
}
.country-flex .flag {
  margin-right: 10px;
}
.country-flex .info-icon {
  font-size: 18px;
}
.tax-types {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #e8ebef !important;
  color: #000 !important;
  border-radius: 5px !important;
  cursor: pointer;
}
.overview-card .content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
/* tax-modal */
.tax-modal .dialog-title {
  /* font-weight: 600; */
  font-size: 1rem;
  padding: 15px;
}
.tax-modal .MuiButtonBase-root {
  text-transform: none !important;
  color: #00183d;
}
.tax-modal li.MuiButtonBase-root {
  background-color: #f2f4f7;
}
.tax-modal .MuiButton-containedPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background: linear-gradient(0deg, rgba(0, 24, 61, 0.2), rgba(0, 24, 61, 0.2)),
    #fcfdff;
}
.tax-modal b {
  color: #324763;
  font-weight: 500;
}
.tax-modal .close-icon {
  font-size: 1.2rem;
  color: #00193d;
}
.tax-modal .dialog-footer {
  display: flex;
  justify-content: space-between;
}

.tax-modal .upload-btn {
  color: #fff;
  background-color: #00183d;
  border-radius: 4px;
}
.tax-modal .upload-btn:hover {
  background-color: #00183d;
  color: #e8ecef;
}
/* end tax modal */

.text-gray {
  color: #97a1b1;
}
.select-box {
  display: flex;
  align-items: flex-end;
  padding: 15px;
}

.select-box .select-search {
  flex: 1;
  margin-left: 20px;
}

.select-box #select-period {
  /* padding: 10px; */
  background-color: #e9ebef;
}
.select-box #select-period .MuiSvgIcon-root {
  display: none;
}
.select-box .select-search label {
  color: #324664;
  margin-bottom: 5px;
}
.select-box .select-search .MuiOutlinedInput-notchedOutline {
  border: none;
}

.select-period-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* font-size: 12px; */
}
.select-period-options .MuiSvgIcon-root {
  font-size: 14px;
}
.select-period-date-icon {
  display: flex;
  gap: 5px;
}
.header-search-field,
.MuiPaper-elevation.MuiPaper-elevation1.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  background-color: #f2f4f7 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.header-search-field .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-bottom: 1px solid #ddd !important;
}
.breadcrumb {
  font-size: 16px;
  margin-bottom: 10px;
}
.breadcrumb .link {
  text-decoration: none;
  cursor: pointer;
}
.breadcrumb .link .text {
  color: #97a1b1;
  border-bottom: 1px solid #97a1b1;
}
.breadcrumb .link.current .text {
  color: #324664;
  border-bottom: none;
  cursor: auto;
}
.breadcrumb .link .MuiSvgIcon-root {
  font-size: 16px;
  vertical-align: middle;
  color: #97a1b1;
}
.ml-1 {
  margin-left: 5px;
}

.search-header {
  padding: 15px;
}
.search-header .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.search-header
  .MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  background: #e8ebef;
  border-radius: 4px;
  height: 44px;
  font-size: 16px;
}
.search-header fieldset {
  border: none;
}
.search-header .search-icon {
  color: #00183d;
}
.status-text {
  padding: 2px 16px 3px 16px;
  border-radius: 30px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
.bg-green {
  background-color: #00c376;
}
.bg-orange {
  background-color: #ff9800;
}
.bg-grey {
  background-color: #e8ebef;
}
.status-text.bg-grey {
  color: #97a1b1;
}
.table-text-grey td {
  color: #97a1b1;
}
.version-table th {
  background-color: #fff;
}
.no-records {
  text-align: center;
}
.version-form .header {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 10px 12px;
}
.form-fields {
  padding: 10px 12px 10px 12px;
}
.form-fields .MuiFormHelperText-root.Mui-error {
  margin-left: 0;
}
.form-fields fieldset,
.drag-table-row fieldset {
  border: none;
}
.form-fields
  .MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd,
.drag-table-row
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  background: #e8ebef;
  border-radius: 4px;
  height: 42px;
  font-size: 16px;
}
.form-fields .multi-row-text .MuiOutlinedInput-root.MuiInputBase-multiline {
  background: #e8ebef;
  border-radius: 4px;
  font-size: 16px;
}
.form-fields
  .multi-row-text
  .MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  height: auto;
}
.form-fields .multi-row-text .MuiFormHelperText-root {
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #65748b;
}
.form-fields label {
  font-size: 16px;
  color: #324664;
  font-weight: 500;
  margin-bottom: 10px;
}
.form-fields .text {
  font-size: 16px;
}
.form-fields #select-dateRange,
.form-fields #select-country {
  background: #e8ebef;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
}

.select-status-text {
  display: block;
  text-align: center;
  width: 90%;
  border-radius: 30px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  /* padding: 5px */
}
.select-status-text.bg-grey {
  color: #97a1b1;
}
.title-box-table {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
}
.edit-footer {
  background-color: #fcfdff;
  padding: 24px 28px 20px;
  position: fixed;
  width: -webkit-fill-available;
  bottom: 0px;
  z-index: 5;
}
.edit-footer-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 60px; */
}

.edit-footer ul {
  color: #e01600;
  /* background-color: #fff6f6; */
  background-color: #fcece9;
  margin: 0px;
  border-radius: 0px 0px 10px 10px !important;
  font-size: 14px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  max-height: 150px;
  overflow-y: scroll;
}

.overlap-error {
  border-radius: 10px 10px 0px 0px !important;
  padding: 0px 16px !important;
}
.overlap-error-default {
  background-color: #f5f8fe !important;
  padding: 0px 16px !important;
  color: #005ae0 !important;
}
.overlap-error-default .MuiAlert-message {
  font-weight: 400;
  font-size: 14px;
}
.edit-footer ul b {
  font-weight: 500;
}

.overlap-error .alert-title {
  color: #e01600;
  font-size: 16px;
  font-weight: 500;
}
.overlap-error-default .alert-title {
  color: #005ae0;
  font-size: 16px;
  font-weight: 500;
}

.disable-op {
  opacity: 0.4;
  pointer-events: none;
}
.add-icon {
  display: none !important;
  position: absolute !important;
  padding: 5px !important;
  font-size: 22px;
  color: #00183d;
}
.order-icon {
  cursor: grab !important;
}
.add-icon svg {
  font-size: 22px;
  color: #00183d;
}
.drag-table-row {
  cursor: auto !important;
  vertical-align: baseline !important;
}
.drag-table-row:hover .add-icon {
  display: inline-flex !important;
}
.drag-table-row:hover
  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall {
  border-bottom: 1px solid #00183d;
  position: relative;
}
.attribute-table th {
  background-color: #fff;
}
.attribute-table > thead > tr:hover .MuiTableCell-root.MuiTableCell-head {
  border-bottom: 1px solid #00183d;
}
.attribute-table > thead > tr:hover .add-icon {
  display: inline-flex !important;
  left: 37px;
  top: 11px;
}
/* .version-detail {
  margin-bottom: 60px;
} */
.version-detail-head {
  display: flex;
  justify-content: space-between;
  padding: 5px 12px 5px 10px;
}
.form-fields .react-datepicker-wrapper {
  width: 100%;
}
.drag-table-row .calculator-btn {
  background-color: #00183d;
  border-radius: 4px;
  margin-right: 5px;
}
.drag-table-row .calculator-btn.bg-grey,
.drag-table-row .calculator-btn.bg-grey:hover {
  background-color: #e8ebef;
}
.drag-table-row .calculator-btn:hover,
.drag-table-row .calculator-btn:active {
  background: #00183d;
}
.formula-modal .formula-text {
  /* background-color: #f2f4f7; */
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.formula-result-field input {
  height: 1em;
}
.formula-modal .formula-text::-webkit-scrollbar {
  display: none;
}
.formula-modal .formula-text fieldset {
  border: none;
}
.formula-tags {
  border-radius: 8px !important;
  margin-right: 5px;
  font-weight: 500;
  /* background-color: #f2f4f7 !important; */
  background-color: #e9ebef !important;
}
.formula-modal .cal-btn-list {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.formula-modal .cal-btn-list button {
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  background-color: #e8ebef;
  color: #00183d;
  text-align: center;
  min-width: 47px;
  height: 26px;
}
.formula-modal .dialog-title {
  font-weight: 500;
  font-size: 1rem;
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.formula-result-field {
  display: flex;
  align-items: center;
  background-color: #f2f4f7;
  padding-right: 10px;
}

/* datePicker  */

.react-datepicker {
  display: flex !important;
  flex-direction: column !important;
}

.react-datepicker__header,
.react-datepicker__month-container,
.react-datepicker {
  background: #e8ebee !important;
  background-color: #e8ebee !important;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: none;
  color: #000;
  border: 0px !important;
  border-radius: 0px !important;
  display: inline-block;
  position: relative;
  padding: 10px;
  margin-top: -10px;
  padding-top: 24px;
  border-radius: 10px !important;
}
.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  display: none;
}
.react-datepicker-popper {
  width: 21vw;
  z-index: 9 !important;
}