.tenant-card {
    height: 96px;
    align-self: stretch !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: stretch !important;
    padding: 12px !important;
    border-radius: 12px !important;
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.06), -1px -1px 1px 0 rgba(255, 255, 255, 0.6), inset 1px 1px 2px 0 rgba(255, 255, 255, 0.6), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.08) !important;
    background-color: var(--future-tax-8) !important;
    width: 100% !important;

}