.active-item {
    background-color: var(--bg_dark) !important;
}

.sidebar-item>div>.MuiListItemText-root>.MuiTypography-root {
    color: var(--grey) !important;
    font-weight: 500 !important;

}

.drawer-container>.MuiPaper-root {
    margin-top: 103px !important;
    background-color: rgba(255, 255, 255, 0.4) !important;
    padding: 0 10px 0px 10px !important;
    border: 0px !important;
    transition: all 0.2s ease-in !important;
}

.sidebar-item {
    min-width: 220px;
    margin-bottom: 16px;

}