.modal-message {
    margin: 8px 16px 16px 16px;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 400;
    position: relative;
}

.modal-message .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: normal;
}

.modal-message .message {
    font-size: 14px;
    line-height: 1.45;
    letter-spacing: normal;
}

.modal-message.success {
    background-color: var(--success-4);
    color: var(--success-100);
}

.modal-message.error {
    background-color: var(--error-4);
    color: var(--error-100);
}

.btn-snackbar-close {
    position: absolute;
    top: 16px;
    right: 20px;
}